import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CorePlayContent from "./CorePlayContent";

const CorePlays = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      overflowX: "auto",
      flexGrow: 1
    },
    paper: {
      // width: "100%",
      padding: theme.spacing(2),
      margin: theme.spacing(2)
    },
    grid: {
      padding: theme.spacing(2)
    },
    datelabel: {
      marginRight: theme.spacing(2)
    }
  }));
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const handleDatePickerChange = e => {
    console.log("Changing date");
    handleDateChange(e);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        {/* <Paper className={classes.paper}> */}
        <Grid
          container
          className={classes.grid}
          justify="center"
          directin="row"
          alignItems="center"
        >
          <Typography variant="h3">Core Plays</Typography>
          <Typography variant="subtitle1">
            Nightly picks from our experts. Just purchase and view the daily core picks below.
          </Typography>
        </Grid>
        <Grid
          container
          className={classes.grid}
          justify="center"
          directin="row"
          alignItems="center"
        >
          <Typography className={classes.datelabel} variant="h6">
            Date:
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker value={selectedDate} onChange={handleDatePickerChange} />
          </MuiPickersUtilsProvider>
        </Grid>
        <CorePlayContent value={selectedDate} />
      </div>
    </React.Fragment>
  );
};

export default CorePlays;
