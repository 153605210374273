import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { Card, CardMedia, CardContent } from "@material-ui/core";
import {
  checkSubscribedToNFL,
  checkPurchasedCorePlays,
  checkCorePlayImageExists,
  createStripeSession
} from "../../utils/helpers";
import {
  isAuthenticated,
  getUserInfo,
  loginWRedirect,
  login,
  handleAuthentication
} from "../../utils/auth";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { navigate } from "gatsby";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

const CorePlayContent = ({ value }) => {
  handleAuthentication(() => {
    console.log("CorePlayContent.handleAuthentication");
    window.heap.track("Auth from core plays");
    navigate("/nba/core-plays");
  });
  const classes = useStyles();
  const [imageExistsStatus, setImageExistsStatus] = useState(false);
  const [hasUserPurchased, setUserPurchased] = useState(false);
  const [isSubscribedNLF, setSubscribedNLF] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeSubSessionId, setStripeSubSessionId] = useState(null);

  const stringDate = format(value, "MM-dd-yyyy");

  const loginM = () => {
    loginWRedirect("/nba/core-plays");
  };

  useEffect(() => {
    console.log("useEffect");
    setUserPurchased(false);
    setImageExistsStatus(false);
    const profile = typeof window !== "undefined" ? getUserInfo() : "";
    setStripe(window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY));

    const checkImageExists = async () => {
      console.log("checkImageExists");
      //   TODO: put this back
      const res = await checkCorePlayImageExists(
        "https://s3.us-east-1.amazonaws.com/tfb.coreplays/" + stringDate + ".jpg"
      );
      if (res === 1) {
        console.log("Setting the image to exists");
        setImageExistsStatus(true);
      } else {
        console.log("Setting the image to NOT exist");
        setImageExistsStatus(false);
      }
    };

    // not giving NFL users free access here
    // const checkSubscription = async () => {
    //   const res = await checkSubscribedToNFL(profile.email);
    //   if (res === 0) {
    //     setSubscribedNLF(false);
    //   } else {
    //     setSubscribedNLF(true);
    //   }
    // };

    const checkPurchased = async () => {
      console.log("checkPurchased");
      const res = await checkPurchasedCorePlays(profile.email, stringDate);
      if (res === 0) {
        console.log("Setting the user to NOT purchased");
        setUserPurchased(false);
        await checkImageExists();
      } else {
        console.log("Setting the user to purchased");
        setUserPurchased(true);
        await checkImageExists();
      }
    };
    // checkSubscription();
    checkPurchased();
  }, [stringDate]);

  const redirectUserToCheckoutForSubscribe = async event => {
    if (!isAuthenticated()) {
      //   login();
      console.log("User not authenticated");
    } else {
      event.preventDefault();
      window.heap.track("To checkout for Core Plays Subscription", { stringDate });
      const profile = typeof window !== "undefined" ? getUserInfo() : "";
      console.log(profile);
      const sessionId = await createStripeSession(profile.email);
      setStripeSubSessionId(sessionId);
      console.log("Sending user to checkout");
      console.log(stripeSubSessionId);
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId
      });
      if (error) {
        console.warn("Error:", error);
      }
    }
  };

  const redirectUserToCheckout = async event => {
    if (!isAuthenticated()) {
      //   login();
      console.log("User not authenticated");
    } else {
      event.preventDefault();
      window.heap.track("To checkout for Core Plays", { stringDate });
      const profile = typeof window !== "undefined" ? getUserInfo() : "";
      const { error } = await stripe.redirectToCheckout({
        items: [{ sku: process.env.STRIPE_NBA_DAILY_CORE_PLAY_SKU, quantity: 1 }],
        customerEmail: profile.email,
        successUrl: process.env.STRIPE_NBA_CORE_PLAY_SUCCESS_URL,
        cancelUrl: process.env.STRIPE_CANCEL_URL
      });
      if (error) {
        console.warn("Error:", error);
      }
    }
  };
  if (!imageExistsStatus) {
    return (
      <React.Fragment>
        <Typography>
          It looks like we do not have core picks for that day. Please select another date.
        </Typography>
      </React.Fragment>
    );
  } else {
    if (!isAuthenticated()) {
      return (
        <React.Fragment>
          <Paper>
            <Grid
              container
              className={classes.grid}
              justify="center"
              directin="row"
              alignItems="center"
            >
              <Typography variant="h6">You are not signed in. Please sign in below.</Typography>
            </Grid>
            <Grid
              container
              className={classes.grid}
              justify="center"
              directin="row"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.button}
                onClick={loginM}
              >
                Login
              </Button>
            </Grid>
          </Paper>
        </React.Fragment>
      );
    }
    if (!hasUserPurchased && !isSubscribedNLF) {
      return (
        <React.Fragment>
          <Paper>
            <Grid
              container
              className={classes.grid}
              justify="center"
              directin="row"
              alignItems="center"
            >
              <Typography variant="h6">You have not purchased Core Picks for this day</Typography>
            </Grid>
            <Grid
              container
              className={classes.grid}
              justify="center"
              directin="row"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={event => redirectUserToCheckout(event)}
              >
                Buy Now
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={event => redirectUserToCheckoutForSubscribe(event)}
              >
                Subscribe Now
              </Button>
            </Grid>
          </Paper>
        </React.Fragment>
      );
    }
    const imageLoc = "https://s3.amazonaws.com/tfb.coreplays/" + stringDate + ".jpg";
    const imageTitle = "Core plays for " + stringDate;
    return (
      <React.Fragment>
        <Paper>
          {/* <Typography variant="h6">Last updated: </Typography> */}
          <Card>
            <CardContent>
              <CardMedia
                component="img"
                alt="Image that should be rendered"
                image={imageLoc}
                title={imageTitle}
              />
            </CardContent>
          </Card>
        </Paper>
      </React.Fragment>
    );
  }
};

export default CorePlayContent;
